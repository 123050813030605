import React from "react";
import HeroImage from "../assets/hero.png"; // Importing the hero image

function BlackContentSection() {
  return (
    <div className="relative text-center text-white h-screen-60">
      <img src={HeroImage} alt="Hero" className="w-full h-full object-cover" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-hero-blue bg-opacity-90 p-12 rounded-lg w-3/4 md:w-1/2 h-auto min-h-[30%] flex flex-col justify-center items-center">
        <h1 className="text-2xl md:text-4xl mb-4">
          Bespoke web development, design and consultancy
        </h1>

        <a
          href="mailto:guy@reactwebsolutions.co.uk?subject=Web%20development%20services&body=Hi,%20I%20am%20looking%20for%20help%20with%20a%20website"
          className="underline text-lg"
        >
          Contact us
        </a>
      </div>
    </div>
  );
}

export default BlackContentSection;
