import React from "react";
import NavBar from "./components/NavBar";
import BlackContentSection from "./components/BlackContentSection";
import WhiteContentSection from "./components/WhiteContentSection";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <NavBar />
      <BlackContentSection />
      <WhiteContentSection />
      <Footer />
    </div>
  );
}

export default App;
