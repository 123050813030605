import React from "react";

function WhiteContentSection() {
  return (
    <div className="flex flex-col justify-center items-center bg-white py-12">
      <div className="flex flex-wrap justify-center gap-10 max-w-4xl mx-auto mb-6">
        <div className="bg-gray-100 p-6 rounded-lg shadow-md w-full md:w-1/3">
          <h2 className="text-xl font-bold mb-4">Websites</h2>
          <p>
            Custom websites tailored to achieve your specific goals and
            outcomes.
          </p>
          <ul className="list-disc list-inside mt-4">
            <li>Website Design</li>
            <li>Website Hosting</li>
          </ul>
        </div>

        <div className="bg-gray-100 p-6 rounded-lg shadow-md w-full md:w-1/3">
          <h2 className="text-xl font-bold mb-4">Consultancy</h2>
          <p>
            Expert consultancy to resolve complex issues or develop new
            solutions.
          </p>
          <ul className="list-disc list-inside mt-4">
            <li>Web Solutions</li>
            <li>Server Solutions</li>
          </ul>
        </div>
      </div>

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-xl"
        onClick={() =>
          (window.location =
            "mailto:guy@reactwebsolutions.co.uk?subject=Web%20development%20services&body=Hi,%20I%20am%20looking%20for%20help%20with%20a%20website")
        }
      >
        Contact Us
      </button>
    </div>
  );
}

export default WhiteContentSection;
