import React from "react";
import Logo from "../assets/logo.png"; // Importing the logo

function NavBar() {
  return (
    <div className="bg-custom-blue h-15 w-full flex items-center px-4">
      <img src={Logo} alt="Reactwebsolutions Logo" className="h-12" />
      <h2 className="text-white text-xl ml-3">Reactwebsolutions</h2>
    </div>
  );
}

export default NavBar;
