import React from "react";

function Footer() {
  return (
    <footer className="bg-gray-200 text-center p-5">
      <p>Reactwebsolutions</p>
      <p>Copyright © reactwebsolutions Ltd 2023</p>
      <p>Email: guy@reactwebsolutions.co.uk</p>
    </footer>
  );
}

export default Footer;
